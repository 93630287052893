import './App.css';
import MySearchBar from './components/searchbyid';

function App() {
  return (
    <div className="App">
        <MySearchBar/>
    </div>
  );
}

export default App;
